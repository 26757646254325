import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Mic, Send, Headphones, Loader2, Globe } from 'lucide-react';
import Select from 'react-select';
import { signInAnonymously, signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth';
import { AuthContext } from './AuthContext'; // Import AuthContext to manage user state
import { auth } from './firebase';
import Header from './Header';
import supabase from './supabaseClient';

const Create = () => {
  const location = useLocation(); // Get location
  const searchTermFromDiscover = location.state?.searchTerm || ''; // Retrieve searchTerm from state
  const [inputText, setInputText] = useState(searchTermFromDiscover);
  const { user, setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [generatedEpisodesToday, setGeneratedEpisodesToday] = useState(0); // Initialize with 0
  const [isCreating, setIsCreating] = useState(false); // Track if the user is creating a podcast

  
  const elevenlabs_languages = {
      "English": ['XB0fDUnXU5powFXDhCwa', '7p1Ofvcwsv7UBPoFNcpI', 'onwK4e9ZLuTAKqWW03F9'],
      "Polish": [],
      "German": [],
      "Spanish": [],
      "French": [],
      "Italian": [],
      "Hindi": [],
      "Portuguese": [],
      "Chinese": [],
      "Korean": [],
      "Russian": [],
      "Dutch": [],
      "Turkish": [],
      "Swedish": [],
      "Indonesian": [],
      "Filipino": [],
      "Japanese": [],
      "Ukrainian": [],
      "Greek": [],
      "Czech": [],
      "Finnish": [],
      "Romanian": [],
      "Danish": [],
      "Bulgarian": [],
      "Malay": [],
      "Slovak": [],
      "Croatian": [],
      "Arabic": [],
      "Tamil": [],
      "Vietnamese": [],
      "Hungarian": [],
      "Norwegian": []
  };

  const languageOptions = [
    { value: 'en', label: 'English' },
    { value: 'hi', label: 'Hindi' },
    { value: 'es', label: 'Spanish' },
    { value: 'fr', label: 'French' },
    ...Object.keys(elevenlabs_languages)
      .filter(lang => !['English', 'Hindi', 'Spanish', 'French'].includes(lang))
      .map(lang => ({ value: lang.toLowerCase(), label: lang }))
  ];

  const [selectedLanguage, setSelectedLanguage] = useState(
    languageOptions.find(option => option.label === 'English')
  );
  const handleLanguageChange = (selectedOption) => {
    setSelectedLanguage(selectedOption);
  };
  // Fetch generated_episodes_today and is_creating when the user is logged in
  const fetchUserData = async () => {
    if (user && !user.isAnonymous) {
      try {
        const { data: userData, error } = await supabase
          .from('users')
          .select('generated_episodes_today, is_creating')
          .eq('id', user.email)
          .single(); // Get the single record for the user

        if (error) {
          console.error('Error fetching user data:', error);
          return;
        }

        setGeneratedEpisodesToday(userData.generated_episodes_today);
        setIsCreating(userData.is_creating);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [user]);

  // Subscribe to changes in generated_episodes_today and is_creating to reflect changes immediately
  useEffect(() => {
    if (user && !user.isAnonymous) {
      const channel = supabase
        .channel('custom-all-channel')
        .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'users', filter: `id=eq.${user.email}` }, (payload) => {
          setGeneratedEpisodesToday(payload.new.generated_episodes_today);
          setIsCreating(payload.new.is_creating);
        })
        .subscribe();

      return () => {
        supabase.removeChannel(channel);
      };
    }
  }, [user]);

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      // Check if the user already exists
      const { data: existingUser, error: fetchError } = await supabase
        .from('users')
        .select('id')
        .eq('id', user.email);
      
      if (fetchError) {
        console.error('Error fetching user:', fetchError);
        return;
      } 
  
      if (Array.isArray(existingUser) && existingUser.length === 0) {
        // User doesn't exist, insert with default values
        const { error: insertError } = await supabase.from('users').insert([
          {
            id: user.email,
            generated_episodes: 0,
            generated_episodes_today: 0,
            is_creating: false,
            created_at: new Date().toISOString(),
            timezone: timezone,  // Add timezone here
          }          
        ]);
  
        if (insertError) {
          console.error('Error inserting user:', insertError);
          return;
        }
  
      } else {
        console.log('');
      }
  
      // Set user state and store in localStorage
      setUser(user);
      localStorage.setItem('authUser', JSON.stringify(user));
      fetchUserData(); // Fetch the user's episodes and status after login
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setUser(null); // Set user to null after signing out
      localStorage.removeItem('authUser'); // Remove user from local storage
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  useEffect(() => {
    const anonymousLogin = async () => {
      const storedAuthUser = localStorage.getItem('authUser');
      if (storedAuthUser) {
        setUser(JSON.parse(storedAuthUser));
      } else {
        try {
          const userCredential = await signInAnonymously(auth);
          const authUser = userCredential.user;
          localStorage.setItem('authUser', JSON.stringify(authUser));
          setUser(authUser);
        } catch (error) {
          console.error('Anonymous login failed:', error);
        }
      }
    };

    anonymousLogin();
  }, []);

  const handleSearch = (e) => {
    const searchTerm = e.target.value; // Extract search term from event
    navigate(`/`, { state: { searchTerm } }); // Navigate to home page with search term
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user || user.isAnonymous) {
      handleGoogleSignIn();
    } else if (!isCreating && generatedEpisodesToday < 3) { // Check conditions before allowing generation
      setIsLoading(true);
      setIsCreating(true); // Set creating state to true
        const response = await fetch(process.env.REACT_APP_API_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ search_query: inputText, email: user.email, name: user.displayName}),
        });
      setInputText('');
      setIsLoading(false);
    
    }
  };

  // Function to autofill search with selected text
  const autofillSearch = (text) => {
    if (!user || user.isAnonymous) {
      // Trigger Google Sign-In if not logged in
      handleGoogleSignIn();
      setInputText(text);
    } else {
      setInputText(text);
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#1f2937',
      borderColor: '#374151',
      '&:hover': {
        borderColor: '#4b5563',
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#1f2937',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#374151' : '#1f2937',
      color: 'white',
      '&:active': {
        backgroundColor: '#4b5563',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
    }),
    input: (provided) => ({
      ...provided,
      color: 'white',
    }),
  };

  return (
    <div className="min-h-screen bg-[#121212] flex flex-col relative">
      <Header searchTerm="" onSearch={handleSearch} />
      
      <main className="flex-1 flex flex-col items-center justify-center px-4 py-4 mt-[-60px]">
        {isCreating ? ( // Show loading screen when is_creating is true
    <div className="w-full max-w-3xl bg-gradient-to-br from-gray-900 to-black rounded-lg shadow-2xl p-8 space-y-8 relative flex flex-col items-center justify-center">
    <div className="animate-pulse flex flex-col items-center">
      <div className="relative">
        <Loader2 className="w-32 h-32 text-red-500 animate-spin" />
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-24 h-24 bg-black rounded-full"></div>
        </div>
      </div>
      <p className="mt-4 text-xl text-gray-300">Creating your podcast. This will take about 10 minutes...</p>
      <div className="mt-8 w-64 h-3 bg-gray-700 rounded-full overflow-hidden">
        <div className="w-1/3 h-full bg-gradient-to-r from-red-500 to-pink-500 animate-loadingBar"></div>
      </div>
    </div>
    <p className="mt-12 text-white text-lg text-center">
      While you wait, why not explore other{' '}
      <Link to="/" className="text-red-500 hover:text-red-400 underline transition-colors duration-300">
        golpos
      </Link>
      ?
    </p>
  </div>
        ) : (
          <div className="w-full max-w-3xl bg-black bg-opacity-70 rounded-lg shadow-2xl p-8 space-y-8 relative">
            {/* Podcasts Left Indicator - Show only if logged in */}
            {user && !user.isAnonymous && (
              <div className="absolute top-4 right-4 bg-gradient-to-r from-purple-600 to-red-500 text-white rounded-full px-6 py-2 shadow-lg text-sm font-semibold">
                {3 - generatedEpisodesToday}/3 podcasts left today
              </div>
            )}

            <div className="text-center">
              <h2 className="text-4xl font-bold text-white mb-2">
                Welcome,{' '}
                <span className="text-red-500">
                  {user && user.displayName ? user.displayName.split(' ')[0] : 'Human'}
                </span>
              </h2>
              <p className="text-gray-300 text-lg">Generate your own production-quality true crime podcast</p>
            </div>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="relative">
                <textarea
                  className="w-full h-48 p-4 bg-gray-900 border border-gray-700 rounded-lg shadow-inner focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent resize-none transition-all duration-300 ease-in-out text-white"
                  placeholder="Describe what you want to hear..."
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                ></textarea>
                <button
                  type="submit"
                  className="absolute bottom-4 right-4 text-red-500 hover:text-red-400 transition-colors duration-300"
                  disabled={!inputText.trim() || isCreating || generatedEpisodesToday >= 3} // Disable based on conditions
                >
                  <Send className="w-6 h-6" />
                </button>
              </div>
              <div className="flex items-center space-x-4">
                <Globe className="w-6 h-6 text-red-500" />
                <Select
                  options={languageOptions}
                  value={selectedLanguage}
                  onChange={handleLanguageChange}
                  styles={customStyles}
                  className="w-full"
                  isSearchable
                />
              </div>
            </form>
            <div className="flex flex-wrap justify-center gap-4">
              {['Doctor Murder', 'College Dorm', 'Parkland School Shooting'].map((text, index) => (
                <button
                  key={index}
                  onClick={() => autofillSearch(text)}  // Set inputText when clicked if logged in
                  className="px-6 py-3 bg-gray-800 text-red-400 border border-red-500 rounded-full shadow-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 transition-all duration-300 ease-in-out flex items-center space-x-2"
                >
                  <span>{text}</span>
                </button>
              ))}
            </div>
            <div className="text-center">
              <button
                onClick={handleSubmit} // Trigger submission on click if logged in
                className={`px-8 py-4 rounded-full shadow-lg transition-all duration-300 ease-in-out flex items-center space-x-2 mx-auto ${
                  isCreating || generatedEpisodesToday >= 3
                    ? 'bg-gray-500 text-gray-300 cursor-not-allowed'
                    : 'bg-red-600 text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50'
                }`}
                disabled={isCreating || generatedEpisodesToday >= 3} // Disable based on conditions
              >
                <Headphones className="w-6 h-6" />
                <span>Generate Episode</span>
              </button>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default Create;
